/*
 * @Author:weixin
 * @Date: 2019-12-24 14:34:31
 * @LastEditors: weixin
 * @LastEditTime: 2019-12-10 19:34:33
 */
export default [
  {
    path: '/renewal',
    name: 'renewal',
    meta: {
      title: '续期/保单'
    },
    component: () =>
      import('@/views/hx/renewal/index')
  },
  {
    path: '/xqShow',
    name: 'xqShow',
    meta: {
      title: '保单详情'
    },
    component: () =>
      import('@/views/hx/renewal/component/index')
  }
]
