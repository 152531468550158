/*
 * @Author: 王鹏
 * @Date: 2019-10-29 14:56:00
 * @LastEditors: 刘格优
 * @LastEditTime: 2021-01-28 18:45:12
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routers'
import store from '@/store'
import { getWxURLInfo } from '@/api/basic'
import { setStorage, getStorage } from '@/lib/util'
import config from '@/config'
import { Dialog } from 'mand-mobile'

Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: 'history'
})

/** 获取当前的hostname
 * 根据域名获取hostname
 * 目前一共有三种:
 * 1.  nhwx(南华网销)————{对应的的登录页路由是'/login',对应的的首页路由是'/'}
 * 2.  nhsx(南华寿险)————{对应的的登录页路由是'/sxlogin',对应的的首页路由是'/sxhome'}
 * 3.  hcdl(汇才代理)————{对应的的登录页路由是'/sxlogin',对应的的首页路由是'/sxhome'}
 */
// let HOSTNAME = 'nhwx'
// let HN_ROOT = ['nhsx', 'hcdl']

/*
 * no表示该页面游客不能直接访问
 * yes表示该页面游客可以直接访问
 * 但是这两种情况都需要和微信交互获取token
 * 而pass表示直接可以访问并且不需要再和微信交互获取token
 * */

let valid = {
  '/': 'no',
  '/confirm': 'yes',
  '/addmember': 'yes',
  '/report': 'yes',
  '/policydetail': 'yes',
  '/detail': 'yes',
  '/visitingcard': 'yes',
  '/Empshare': 'yes',
  '/Emparticle': 'yes',
  '/greetingcard/cardshare': 'yes',
  '/invitationletter/invite': 'yes',
  '/sharereport': 'yes',
  '/website': 'yes',
  '/nbsReport': 'yes',
  '/customercenter': 'yes', // 客户中心
  '/bdtg': 'yes', // 家庭列表
  '/responsibility': 'yes', // 计划书
  '/revealpdf': 'yes', // 计划书pdf
  '/generate': 'yes', // 产品对比报告
  '/looked': 'no', // 消息中心
  '/acquisitionposter': 'no', // 展业海报
  '/Libwarticle': 'yes', // 文章
  // "/Hkbw": "yes",
  '/tuoke': 'yes',
  '/Consult': 'yes', // 咨询
  '/recommendpage': 'yes', // 保单托管客户扫码
  '/productShow': 'yes', // 产品展示分享
  '/Invitenew': 'yes', // 邀新页面
  '/microshop': 'yes', // 门店
  '/productShowNhwx': 'yes', // 产品分享页面
  '/inviteactivity': 'yes',
  '/promote': 'yes',
  '/companyInfo': 'yes',
  '/Invitedetail': 'yes', // 邀新详情页
  '/servicePack': 'yes', // 服务礼包
  '/promoteDetail': 'yes',
  '/teamzy': 'yes', // 增员二维码
  '/promptpage': 'yes' ,// h5产品提示页面
  '/wrong':'yes', //错误页面
  '/shareLinkPage':'yes',
  'subSuccess':'yes',
  '/interInsurance':'yes'
}

// 验证会员身份的客户
let HY_ROOT = ['/microshop', '/cashDetails']

function Oauth (replace_url, router, valid) {
  if (process.env.NODE_ENV !== 'development') {
    getWxURLInfo().then(res => {
      /*
       * 保存要访问的路由对应值和路由
       * */
      setStorage('valid', valid)
      setStorage('r_t', router)
      let {
        appid,
        scope,
        userange,
        sharelogo,
        applogo,
        appsysname,
        stylenum,
        comlogo,
        leadimg,
        mplogo,
        theme,
        sylogo
      } = res.data
      setStorage('a_d', appid)
      setStorage('sys_info', {
        userange,
        sharelogo,
        applogo,
        appsysname,
        stylenum,
        comlogo,
        leadimg,
        mplogo,
        theme,
        sylogo
      })
      window.location.replace(
        `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${replace_url}&response_type=code&scope=${scope}&state=STATE#wechat_redirect`
      )
    })
  } else {
    localStorage.setItem('u_s', JSON.stringify(getStorage('u_s', {})))
    localStorage.setItem('i_f', JSON.stringify(getStorage('i_f', {})))
    localStorage.setItem('m_l', JSON.stringify(getStorage('m_l', {})))
    localStorage.setItem('r_t', JSON.stringify(getStorage('r_t', '')))
    localStorage.setItem('ossurl', JSON.stringify(getStorage('ossurl', '')))
    localStorage.setItem('valid', JSON.stringify(getStorage('valid', '')))
    // 本地开发默认南华网销的身份
    localStorage.setItem('hostname', 'nhwx')
    localStorage.setItem(
      'sys_info',
      JSON.stringify(getStorage('sys_info', ''))
    )
    localStorage.setItem('theme', '#11579D')
  }
}

let isEntry = () => {
  if (
    getStorage('u_s', '').rytype == 'W' ||
    getStorage('u_s', '').rytype == 'N' ||
    getStorage('u_s', '').rytype == 'M'
  ) {
    return true
  } else {
    return false
  }
}

router.beforeEach((to, from, next) => {
  /**
   * 判断跳转是否为登录页面，如果不是登录页面
   * 则路由跳转展示加载中页面
   */

  if (to.path != '/login' && to.path != '/' && to.path != '/oauth' && to.path != '/search') {
    store.state.app.isloading = true
    if (
      to.path != '/policydetail' &&
      to.path != '/report' &&
      to.path != '/detail' &&
      to.path != '/Invitedetail'
    ) {
      setTimeout(() => {
        store.state.app.isloading = false
      }, 1000)
    }
  }
  /*
   *  判断是不是管理端访问页面
   *  */
  /*
   * 如果访问的路由对应的值为pass,
   * 直接通过
   * ['/oauth','/appoauth','/onFile']
   * */
  if (
    (to.query.type && to.query.type == 'pc') ||
    to.path == '/oauth' ||
    to.path == '/appoauth' ||
    to.path == '/onFile' || 
    to.path == '/revealpdf'||
    to.path == '/shareLinkPage'||
    to.path == '/subSuccess'||
    to.path == '/interInsurance'
  ) {
    // 管理端访问手机端页面时,默认系统颜色就是该色值
    // eslint-disable-next-line no-unused-expressions
    to.query.type == 'pc' ? setStorage('theme', '#EC2D38') : ''
    next()
  } else {
    setStorage('theme', '#EC2D38')
    if (valid[to.path] == 'yes' && !to.params.yes) to.params.yes = true
    if (!getStorage('u_s', '') && !getStorage('reject', false)) {
      /*
       * 首次访问路由
       * 先和微信交互获取token
       *
       * */
      setStorage('hostname', window.location.hostname.split('.')[0])
      let replace_url = config.redirect_uri + '/oauth'
      Oauth(replace_url, to.fullPath, valid[to.path])
    } else {
      /*
       * 已有缓存
       * */
      if (!isEntry()) {
        /*
         * 状态是游客
         * */
        if (getStorage('valid') == 'yes') {
          /*
           * 如果valid等于yes
           * */

          next()
        } else {
          /*
           * 如果valid等于no
           * */
          /*
           * 如果valid不等于yes也不等于no
           * */
          if (
            to.path == '/login' ||
            to.path == '/sxlogin' ||
            to.path == '/register' ||
            to.path == '/yzmlogin' ||
            to.path == '/vipserve' ||
            to.path == '/manment' ||
            to.path == '/statement' ||
            to.path == '/wtxy' ||
            to.path == '/hczyzi' ||
            to.path == '/zcxy' ||
            to.path == 'yjfk'
          ) {
            next()
          } else {
            getStorage('hostname') == 'nhwx'
              ? next('/login')
              : next('/sxlogin')
          }
        }
      } else {
        /*
         * 状态是内勤或外勤
         * */
        if (to.path == '/login' || to.path == '/register') {
          next('/')
        } else if (
          to.path == '/sxlogin' ||
          ((getStorage('hostname') == 'nhsx' ||
            getStorage('hostname') == 'sybx' ||
            getStorage('hostname') == 'hcdl') &&
            to.path == '/')
        ) {
          next('/sxhome')
        } else if (
          HY_ROOT.includes(to.path) &&
          getStorage('u_s', '').rytype == 'W' &&
          getStorage('u_s', '').status == '6' &&
          !to.query.isshare
        ) {
          next('/diaoauth')
        } else if (getStorage('hostname') == 'qd' && to.path == '/') {
          next('/main')
        } else {
          Dialog.closeAll()
          if (process.env.NODE_ENV !== 'development') {
            if (valid[to.path] == 'yes' && !to.query.flush) {
              if (to.fullPath.includes('?')) {
                location.href = `${config.redirect_uri}${to.fullPath
                }&flush=true`
              } else {
                location.href = `${config.redirect_uri}${to.fullPath
                }?flush=true`
              }
            } else if (to.path == '/main' && !to.query.flush) {
              location.replace(`${config.redirect_uri}/main?flush=true`)
            } else {
              next()
            }
          } else {
            next()
          }
        }
      }
    }
  }
})

router.afterEach(to => {
  if (
    (to.path == '/' || to.path == '/oauth') &&
    getStorage('httpflag', {}).cpflag == true
  ) {
    window.document.title = ''
  } else {
    window.document.title = to.meta.title
      ? to.meta.title
      : getStorage('sys_info', {}).appsysname
  }
  if (!window.entryUrl && /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    var url = window.location.href
    window.entryUrl = url // 将后面的参数去除
  }
})

export default router
