/*
 * @Author: 王广婷
 * @Date: 2020-04-09 20:42:36
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-05-09 11:06:31
 */
export default [
  {
    path: "/promote",
    name: "promote",
    meta: {
      title: "首期享三重大礼 最高价值2万元"
    },
    component: () => import("@/views/openpage/promote")
  },
  {
    path: "/servicePack",
    name: "servicePack",
    meta: {
      title: "医疗服务礼包"
    },
    component: () => import("@/views/openpage/servicePack")
  },
  {
    path: "/serviceDetail",
    name: "serviceDetail",
    meta: {
      title: "医疗服务礼包"
    },
    component: () => import("@/views/openpage/serviceDetail")
  },
  {
    path: "/inviteactivity",
    name: "inviteactivity",
    meta: {
      title: "邀新注册赢首发礼品"
    },
    component: () => import("@/views/openpage/invitenew")
  },
  {
    path: "/invitegz",
    name: "invitegz",
    meta: {
      title: "邀新注册赢首发礼品"
    },
    component: () => import("@/views/openpage/invitegz")
  },
  {
    path: "/companyInfo",
    name: "companyInfo",
    meta: {
      title: "汇立天下：新时代保险普惠"
    },
    component: () => import("@/views/openpage/companyInfo")
  },
  {
    path: "/promoteDetail",
    name: "promoteDetail",
    meta: {
      title: ""
    },
    component: () => import("@/views/openpage/promoteDetail")
  }
];
