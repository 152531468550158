/*
 * @Author:王广婷
 * @Date: 2019-11-04 16:56:31
 * @LastEditors: 王广婷
 * @LastEditTime: 2019-11-04 10:58:51
 */
import analysis from './analysis'
import customer from './customer'
import family from './family'

export default [...analysis, ...customer, ...family]
