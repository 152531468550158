/*
 * @Author: 王鹏
 * @Date: 2020-03-06 17:30:33
 * @LastEditors: 王广婷
 * @LastEditTime: 2020-04-23 21:18:14
 */
export default [
  {
    path: '/main',
    name: 'main',
    meta: {
      title: '汇保云服'
    },
    component: () =>
        import('@/views/xsqd')
  },
  {
    path: '/class',
    name: 'class',
    meta: {
      title: '产品'
    },
    component: () =>
        import('@/views/xsqd/class')
  },
  {
    path: '/qdinformation',
    name: 'qdinformation',
    meta: {
      title: '产品'
    },
    component: () =>
        import('@/views/xsqd/qdinformation/index')
  },

  {
    path: '/userinfo',
    name: 'userinfo',
    meta: {
      title: '个人中心'
    },
    component: () =>
        import('@/views/xsqd/userinfo')
  },
  {
    path: '/myOrder',
    name: 'myOrder',
    meta: {
      title: '我的订单'
    },
    component: () =>
        import('@/views/xsqd/myOrder')
  },
  {
    path: '/myorderDetail',
    name: 'myorderDetail',
    meta: {
      title: '订单详情'
    },
    component: () =>
        import('@/views/xsqd/myorderDetail')
  },
  {
    path: '/orderStatus',
    name: 'orderStatus',
    meta: {
      title: '发票申请'
    },
    component: () =>
        import('@/views/xsqd/orderStatus')
  },
  {
    path: '/underwriting',
    name: 'underwriting',
    meta: {
      title: '我的核保'
    },
    component: () =>
        import('@/views/xsqd/orderStatus')
  },
  {
    path: '/surrender',
    name: 'surrender',
    meta: {
      title: '我的退保'
    },
    component: () =>
        import('@/views/xsqd/orderStatus')
  },
  {
    path: '/pdOrder',
    name: 'pdOrder',
    meta: {
      title: '保单批改'
    },
    component: () =>
        import('@/views/xsqd/pdOrder')
  },
  {
    path: '/xsqdsetting',
    name: 'xsqdsetting',
    meta: {
      title: '设置'
    },
    component: () =>
        import('@/views/xsqd/setting')
  },{
    path: '/subSuccess',
    name: 'subSuccess',
    meta: {
      title: '提交成功'
    },
    component: () =>
        import('@/views/xsqd/subSuccess')
  },{
    path: '/interInsurance',
    name: 'interInsurance',
    meta: {
      title: 'H5页面跳转'
    },
    component: () =>
        import('@/views/xsqd/interInsurance')
  }
]
