/*
 * @Author:张天鹏
 * @Date: 2020-03-13 11:52:31
 * @LastEditors: 张天鹏
 * @LastEditTime: 2020-03-13 11:52:31
 */
export default [
  {
    path: '/productcheck',
    name: 'productcheck',
    meta: {
      title: '选择产品'
    },
    component: () =>
        import('@/views/jhs/productcheck/index')
  },
  {
    path: '/fillout',
    name: 'fillout',
    meta: {
      title: '填写信息'
    },
    component: () =>
        import('@/views/jhs/productcheck/component/fillout')
  }
]
