/*
 * @Author:李波
 * @Date: 2019-11-12 09:50:31
 * @LastEditors  : 李波
 * @LastEditTime : 2020-01-08 19:18:45
 */
export default [
  {
    path: '/nbsInfo',
    name: 'nbsInfo',
    meta: {
      title: '顾问式营销'
    },
    component: () =>
      import('@/views/nbs/nbsinfo/info')
  },
  {
    path: '/nbsReport',
    name: 'nbsReport',
    meta: {
      title: 'NBS报告'
    },
    component: () =>
      import('@/views/nbs/nbsinfo/component/nbsreport')
  },
  {
    path: '/entering',
    name: 'entering',
    meta: {
      title: '信息录入'
    },
    component: () =>
      import('@/views/nbs/nbsinfo/component/entering')
  }
]
