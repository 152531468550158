/*
 * @Author:王广婷
 * @Date: 2019-11-04 16:56:31
 * @LastEditors: 贾峰
 * @LastEditTime: 2019-12-04 21:47:21
 */
export default [
  {
    path: '/analysis',
    name: 'analysis',
    meta: {
      title: '解析追踪'
    },
    component: () => import('@/views/abd/analysis/index')
  },
  {
    path: '/policydetail',
    name: 'policydetail',
    meta: {
      title: '解析报告'
    },
    component: () => import('@/views/abd/analysis/component/policydetail')
  }
]
