/*
 * @Author:weixin
 * @Date: 2019-12-24 14:34:31
 * @LastEditors: weixin
 * @LastEditTime: 2019-12-10 19:34:33
 */
export default [
  {
    path: '/newpolicy',
    name: 'newpolicy',
    meta: {
      title: '新保保单'
    },
    component: () =>
      import('@/views/hx/newpolicy/index')
  },
  {
    path: '/xbshow',
    name: 'xbshow',
    meta: {
      title: '保单详情'
    },
    component: () =>
      import('@/views/hx/newpolicy/component/index')
  }
]
