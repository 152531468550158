/*
 * @Author: 王鹏
 * @Date: 2019-10-29 16:57:11
 * @LastEditors: 张天鹏
 * @LastEditTime: 2020-06-18 11:13:52
 */
import module from './modeule'
export default [
  {
    path: '/login',
    alias: '/login',
    name: 'login',
    meta: {
      title: '登录',
      keepAlive: true
    },
    component: () => import('@/views/basic/login/nhlogin')
  },
  {
    path: '/yzmlogin',
    alias: '/yzmlogin',
    name: 'yzmlogin',
    meta: {
      title: '注册',
      keepAlive: true
    },
    component: () => import('@/views/basic/login/yzmlogin')
  },
  {
    path: '/register',
    alias: '/register',
    name: 'register',
    meta: {
      title: '注册'
    },
    component: () => import('@/views/basic/login/register')
  },
  {
    path: '/sxlogin',
    alias: '/sxlogin',
    name: 'sxlogin',
    meta: {
      title: '登录'
    },
    component: () => import('@/views/basic/login/login')
  },
  {
    path: '/',
    name: 'home',
    meta: {
      title: '汇立天下：新时代保险普惠',
      keepAlive: true
    },
    component: () => import('@/views/basic/home/component/component/nhhome')
  },
  {
    path: '/sxhome',
    name: 'sxhome',
    meta: {
      title: window.location.hostname.split('.')[0] == 'hcdl' ? '汇才营销' : '',
      keepAlive: true
    },
    component: () => import('@/views/basic/home/component/schjone')
  },
  {
    path: '/tuoke',
    name: 'tuoke',
    meta: {
      title: ''
    },
    component: () => import('@/views/basic/home/component/drbx')
  },
  {
    path: '/oauth',
    name: 'oauth',
    meta: {
      // 汇立天下：新时代保险普惠
      title: window.location.hostname.split('.')[0] == 'hcdl' ? '汇才营销' : '   '
      // title: window.location.hostname.split('.')[0] == 'hcdl' ? '汇才营销'
      //   : window.location.hostname.split('.')[0] == 'nhwx' ? '汇立天下：新时代保险普惠' : '   '
    },
    component: () => import('@/views/basic/oauth/index')
  },
  {
    path: '/set',
    name: 'set',
    meta: {
      title: '设置'
    },
    component: () => import('@/views/basic/set/index')
  },
  {
    path: '/appoauth',
    name: 'appoauth',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/basic/oauth/appoauth')
  },
  {
    path: '/myCenter',
    name: 'myCenter',
    meta: {
      title: '个人中心'
    },
    component: () => import('@/views/basic/myCenter/myCenter')
  },
  {
    path: '/bankCard',
    name: 'bankCard',
    meta: {
      title: '银行卡信息'
    },
    component: () => import('@/views/basic/myCenter/bankCard/bankCard')
  },
  {
    path: '/wages',
    name: 'wages',
    meta: {
      title: '佣金明细'
    },
    component: () => import('@/views/basic/myCenter/wages/wages')
  },
  {
    path: '/reminders',
    name: 'reminders',
    meta: {
      title: '智能提醒'
    },
    component: () => import('@/views/basic/reminders/index')
  },
  {
    path: '/remindersshow',
    name: 'remindersshow',
    meta: {
      title: '智能提醒详情'
    },
    component: () => import('@/views/basic/reminders/remindersshow/index')
  },
  {
    path: '/advice',
    name: 'advice',
    meta: {
      title: '建议反馈'
    },
    component: () => import('@/views/basic/advice/index')
  },
  {
    path: '/htck',
    name: 'htck',
    meta: {
      title: '合同查看'
    },
    component: () => import('@/views/basic/htck/index')
  },
  {
    path: '/myScore',
    name: 'myScore',
    meta: {
      title: '我的积分'
    },
    component: () => import('@/views/basic/myScore/index')
  },
  ...module.agent,
  ...module.abt,
  ...module.abd,
  ...module.nbs,
  ...module.jhs,
  ...module.cpk,
  ...module.basic,
  ...module.hx,
  ...module.smarkboard,
  ...module.xsqd,
  ...module.xy,
  ...module.openpage
]
