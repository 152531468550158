/*
 * @Author: 王鹏
 * @Date: 2019-09-12 11:46:08
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-04-07 19:18:06
 */
export const CN = {
  messages: {
    required(field) {
      return `请输入${field}`;
    }
  },
  attributes: {
    empno: "工号",
    userName: "用户名",
    password: "密码",
    name: "姓名",
    mobile: "手机号码",
    // 增员相关
    tjname: "推荐人姓名",
    tjempno: "推荐人工号",
    subcom: "所属分公司",
    salesuper: "销售总监",
    cardtype: "证件类型",
    empname: "姓名",
    sex: "性别",
    cardnum: "证件号码",
    nation: "民族",
    birth: "出生日期",
    adress: "详细地址",
    tel: "联系方式",
    edu: "学历",
    politicstatus: "政治面貌",
    bankcount: "银行账户",
    banknum: "银行卡号",
    apidname: "投保人姓名",
    apidage: "投保人年龄",
    pidname: "被保人姓名",
    pidage: "被保人年龄",
    pidsex: "被保人性别",
    age: "年龄",
    lamount: "保额",
    nguacardnum: "内担保人证件号码",
    wguacardnum: "外担保人证件号码",
    email: "邮箱",
    credit: "银行卡号"
  }
};
