/*
 * @Author:魏鑫
 * @Date: 2020-01-09 11:56:31
 * @LastEditors: 魏鑫
 * @LastEditTime: 2020-01-09 21:56:44
 */
import advancestatement from './advancestatement'
import effectivereport from './effectivereport'
import manpower from './manpower'
import renewalreport from './renewalreport'
import kjreport from './kjreport'

export default [...advancestatement, ...effectivereport, ...manpower, ...renewalreport, ...kjreport]