
export default [{
  path: '/vipserve',
  name: 'vipserve',
  meta: {
    title: '会员服务协议'
  },
  component: () => import('@/components/common/hyfw')
},
{
  path: '/statement',
  name: 'statement',
  meta: {
    title: '南华隐私政策声明'
  },
  component: () => import('@/components/common/yszcsm')
},
{
  path: '/wtxy',
  name: 'wtxy',
  meta: {
    title: '委托协议'
  },
  component: () => import('@/components/common/wtxy')
},
{
  path: '/hczyzi',
  name: 'hczyzi',
  meta: {
    title: '核查职业资质授权书'
  },
  component: () => import('@/components/common/hczyzi')
},
{
    path: '/manment',
    name: 'manment',
    meta: {
      title: '会员管理制度'
    },
    component: () => import('@/components/common/manment')
  },
  {
    path: '/zcxy',
    name: 'zcxy',
    meta: {
      title: '注册协议'
    },
    component: () => import('@/components/common/zcxy')
  },
  {
    path: '/yjfk',
    name: 'yjfk',
    meta: {
      title: '投诉意见'
    },
    component: () => import('@/components/common/yjfk')
  },
]
