/*
 * @Author: 王鹏
 * @Date: 2019-09-10 16:36:46
 * @LastEditors: 刘格优
 * @LastEditTime: 2021-02-07 15:27:03
 */

import axios from 'axios'
import router from '@/router'
// import { Toast } from 'mand-mobile'
import mytoast from '../components/mytoast'
import store from '@/store'
import { getStorage, setStorage, clearStorage } from '@/lib/util'
import { refresh,quitlogin} from '@/api/basic'
class HttpRequest {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
    this.queue = {}
    this.isRefresh = false
  }
  getInsideConfig () {
    const config = {
      baseURL: this.baseUrl,
      headers: {}
    }
    return config
  }
  destroy(url) {
    delete this.queue[url]
    if (!Object.keys(this.queue).length) {
      // Toast.hide()
    }
  }
  logOut() {
    let fromwhere = getStorage('fromwhere', '')
    if (fromwhere === 'app') {
      router.push({
        path: '/bdtg',
        query: {
          fromwhere: 'app'
        }
      })
    }
    clearStorage()
    router.push({
      path: '/'
    })
  }
  interceptors (instance, url) {
    // 请求拦截
    instance.interceptors.request.use(
      config => {
        // store.state.app.isloading = true
        const { expireTime, refreshTime, token } = getStorage('i_f', {})
        const now = Date.now()
        // 添加全局的loading...
        if (!Object.keys(this.queue).length) {
          // Toast.loading('加载中...', 0, true)
        }
        this.queue[url] = true

        if (refreshTime < now && expireTime > now && !this.isRefresh) {
          this.isRefresh = true
          refresh().then(res => {
            setStorage('i_f', res.data.data)
          })
        } else if (now > expireTime) {
          // this.logOut()
        }

        if (process.env.NODE_ENV !== 'development') {
          config.headers.Authorization = token
        } else {
          config.headers.Authorization =
            // 'BearereyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJvTWNfc3c4eDFGSVp0SVBFRFlYMGFFRnZnNG9ZJk5IV1gmUyIsImV4cCI6MTYwNTg1NjY2MSwiY3JlYXRlZCI6MTYwNTg1MzY2MTc5OCwiaXAiOiJjZTI2NWU3NzIwYTFiMjRiMzExNDVmYzA4MjQ4ZGE2NiJ9.z_hB2k_wl2QebQKGVt8rG1vCfIxSe5kQweNaxmzb7Q8'
            'BearereyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJvTWNfc3czQUNHdzhNRkRla0tJTGVDUVJ0OW4wJk5IV1gmUyIsImV4cCI6MTYyNzI4NzcyOSwiY3JlYXRlZCI6MTYyNzI4NDcyOTMyNywiaXAiOiJjZTI2NWU3NzIwYTFiMjRiMzExNDVmYzA4MjQ4ZGE2NiJ9.x8wN9HdpKK-VmAINtYB6yQF378dhcZyve-uCSUx2LiY'
        }
        return this.transfromData(config)
      },
      error => {
        return Promise.reject(error)
      }
    )

    // 响应拦截
    instance.interceptors.response.use(
      res => {
        store.state.app.isloading = false
        this.destroy(url)
        const { data, status, headers, config } = res
        const responseTypes = ['arraybuffer', 'blob', 'stream']
        if (data.code && data.code !== 200) {
            if(data.msg.indexOf('您的账户信息未绑定业务员，如需使用请联系管理员')!=-1 || data.code == 120){   
                // console.log("禁用退出")
                sessionStorage.clear()
                localStorage.clear()
                return  router.push({ path: '/sxlogin' })
            }else{
               /*
           * 如果是刷新token接口报401
           * */
             data.code == 401 ? this.logOut() : data.code == 250 ? '' : mytoast.info(data.msg)
          // eslint-disable-next-line prefer-promise-reject-errors
          // return Promise.reject(data.msg);
             return { data }
           }
        } else if (responseTypes.includes(config.responseType)) {
          return {
            data,
            status,
            headers
          }
        } else {
          return {
            data,
            status
          }
        }
      },
      error => {
        // Toast.failed('网络异常,请检查......')

        this.destroy(url)

        let errorInfo = error.response

        if (!errorInfo) {
          const {
            request: { statusText, status },
            config
          } = JSON.parse(JSON.stringify(error))
          errorInfo = {
            statusText,
            status,
            request: {
              responseURL: config.url
            }
          }
        }

        return Promise.reject(error)
      }
    )
  }
  transfromData(config) {
    let types = ['get', 'delete']
    if (types.includes(config.method) && config.params) {
      config.url = Object.keys(config.params).reduce(
        (acc, currt) => (acc += `/${config.params[currt]}`),
        config.url
      )
      config.params = null
    }
    return config
  }
  // async doRequest (error, token) {
  //   let result = await this.request().post('/weixin/isOverdueToken', { token })

  //   const { ...info } = getStorage('i_f', {})
  //   info.token = result.data.data.token
  //   setStorage('i_f', info)

  //   let config = error.response.config

  //   config.headers.Authorization = result.data.token

  //   return await this.request().request(config)
  // }
  request() {
    const instance = axios.create(this.getInsideConfig())
    this.interceptors(instance)
    return instance
  }
}
export default HttpRequest
