/*
 * @Author:张天鹏
 * @Date: 2020-01-02 09:52:31
 * @LastEditors: 张天鹏
 * @LastEditTime: 2020-01-02 09:52:31
 */
export default [
  {
    path: '/drinking',
    name: 'drinking',
    meta: {
      title: '产品对比'
    },
    component: () =>
          import('@/views/jhs/drinking/index')
  },
  {
    path: '/comparerg',
    name: 'comparerg',
    meta: {
      title: '填写对比信息'
    },
    component: () =>
      import('@/views/jhs/drinking/component/comparerg')
  },
  {
    path: '/generate',
    name: 'generate',
    meta: {
      title: '产品对比报告'
    },
    component: () =>
      import('@/views/jhs/drinking/component/generate')
  }
]
