/*
 * @Author:张天鹏
 * @Date: 2020-01-02 09:52:31
 * @LastEditors: 王广婷
 * @LastEditTime: 2020-05-22 10:18:49
 */
export default [
  {
    path: '/orderform',
    name: 'orderform',
    meta: {
      title: '订单详情',
      keepAlive: true
    },
    component: () =>
          import('@/views/cpk/orderform/index')
  }
]
