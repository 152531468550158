/*
 * @Author: 王鹏
 * @Date: 2019-10-29 14:56:00
 * @LastEditors: 刘格优
 * @LastEditTime: 2021-01-28 17:51:58
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import mytoast from './components/mytoast'
import components from './components'
import importDirective from '@/directive'
import transform from '@/lib/transform.js'
import { getStorage } from '@/lib/util'
import i18n from './locale'
import VCharts from 'v-charts'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueSignaturePad from 'vue-signature-pad'
import 'swiper/dist/css/swiper.css'
import './lib/fast-click'
import '@/assets/global.styl'
import '@/assets/util.styl'
import 'amfe-flexible'
import '@/validate'
import Vconsole from 'vconsole'
import echarts from 'echarts'
import config from '@/config'
const { redirect_uri } = config
Vue.use(VCharts)
Vue.use(components)
Vue.use(i18n)
Vue.use(VueAwesomeSwiper)
Vue.use(VueSignaturePad)
Vue.prototype.$mytoast = mytoast
Vue.prototype.$transform = transform
Vue.prototype.$echarts = echarts
Vue.prototype.$store = store
Vue.prototype.getStorage = getStorage
Vue.prototype.redirect_uri = redirect_uri
/**
 * 注册指令
 */
if (process.env.NODE_ENV !== 'production') {
  let vConsole = new Vconsole()
  Vue.use(vConsole)
}
importDirective(Vue)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
