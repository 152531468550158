/*
 * @Author:魏鑫
 * @Date: 2020-01-09 11:50:31
 * @LastEditors: 魏鑫
 * @LastEditTime: 2020-01-09 11 14:22:33
 */
export default [
  {
    path: '/kjreport',
    name: 'kjreport',
    meta: {
      title: '客经报表'
    },
    component: () =>
      import('@/views/smarkboard/kjreport/index')
  },
]
