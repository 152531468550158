/*
 * @Author: 王广婷
 * @Date: 2019-12-06 16:05:49
 * @LastEditors: 王广婷
 * @LastEditTime: 2019-12-06 16:05:49
 */
export default {
  transform: function (value) {
    return value.toLocaleUpperCase()
  }
}
