/*
 * @Author:weixin
 * @Date: 2019-12-24 14:34:31
 * @LastEditors: weixin
 * @LastEditTime: 2019-12-10 19:34:33
 */
export default [
  {
    path: '/preservation',
    name: 'preservation',
    meta: {
      title: '保全业务'
    },
    component: () =>
      import('@/views/hx/preservation/index')
  },
  {
    path: '/bqshow',
    name: 'bqshow',
    meta: {
      title: '保单详情'
    },
    component: () =>
      import('@/views/hx/preservation/component/index')
  }
]
