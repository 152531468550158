/*
 * @Author:张天鹏
 * @Date: 2019-12-10 19:33:31
 * @LastEditors: 张天鹏
 * @LastEditTime: 2020-01-02 10:15:44
 */
import productlist from './productlist'
import drinking from './drinking'
import productcheck from './productcheck'

export default [...productlist, ...drinking, ...productcheck]
