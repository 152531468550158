/*
 * @Author:weixin
 * @Date: 2019-12-24 14:33:31
 * @LastEditors  : 贾峰
 * @LastEditTime : 2019-12-31 11:43:07
 */
import newpolicy from './newpolicy'
import renewal from './renewal'
import preservation from './preservation'
import sett from './sett'
import businesstrack from './businesstrack'
import simplex from './simplex'

export default [...newpolicy, ...renewal, ...preservation, ...sett, ...businesstrack, ...simplex]
