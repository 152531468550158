/*
 * @Author: 刘格优
 * @Date: 2019-12-23 15:29:54
 * @LastEditors: 刘格优
 * @LastEditTime: 2021-01-19 16:52:18
 */
export default [
  {
    path: '/productDisplayNhwx',
    name: 'productDisplayNhwx',
    meta: {
      title: '产品'
    },
    component: () => import('@/views/agent/productDisplayNhwx/index.vue')
  },
  {
    path: '/shareLinkPage',
    name: 'shareLinkPage',
    meta: {
      title: '产品介绍'
    },
    component: () => import('@/views/agent/productDisplayNhwx/shareLinkPage.vue')
  },
  {
    path: '/study',
    name: 'study',
    meta: {
      title: '学习',
      keepAlive: true
    },
    component: () => import('@/views/basic/home/component/component/study')
  },
  {
    path: "/testdetail",
    name: "testdetail",
    meta: {
      title: ""
    },
    component: () =>
      import("@/views/basic/home/component/component/xuexi/component/testdetail")
  },
  {
    path: "/testrecode",
    name: "testrecode",
    meta: {
      title: ""
    },
    component: () =>
      import("@/views/basic/home/component/component/xuexi/component/testrecord")
  },
  {
    path: "/answerdetail",
    name: "answerdetail",
    meta: {
      title: ""
    },
    component: () =>
      import("@/views/basic/home/component/component/xuexi/component/answerdetail")
  },
  {
    path: "/mytw",
    name: "mytw",
    meta: {
      title: ""
    },
    component: () =>
      import("@/views/basic/home/component/component/xuexi/component/mytw")
  },
  {
    path: "/nhcenter",
    name: "nhcenter",
    meta: {
      title: '个人中心'
    },
    component: () => import('@/views/basic/home/component/component/nhcenter')
  },
  {
    path: '/help',
    name: 'help',
    meta: {
      title: '使用帮助'
    },
    component: () => import('@/views/basic/help/index')
  },
  {
    path: '/bszt',
    name: 'bszt',
    meta: {
      title: '保司投保链接'
    },
    component: () => import('@/views/basic/bszt/index')
  },
  {
    path: '/helpqd',
    name: 'helpqd',
    meta: {
      title: '帮助文档'
    },
    component: () => import('@/views/basic/helpgl/index')
  },
  {
    path: '/customerserver',
    name: 'customerserver',
    meta: {
      title: '客服中心'
    },
    component: () => import('@/views/basic/customer/index')
  },
  {
    path: '/yhqshow',
    name: 'yhqshow',
    meta: {
      title: '优惠券'
    },
    component: () => import('@/views/basic/yhq/index')
  },
  {
    path: '/yhqdetail',
    name: 'yhqdetail',
    meta: {
      title: '优惠券'
    },
    component: () => import('@/views/basic/yhq/detail')
  },
  {
    path: '/policyDetails',
    name: 'policyDetails',
    meta: {
      title: '收入明细'
    },
    component: () =>
      import('@/views/basic/home/component/component/setUp/policyDetails.vue')
  },
  {
    path: '/commissionDetails',
    name: 'commissionDetails',
    meta: {
      title: '提现明细'
    },
    component: () =>
      import('@/views/basic/home/component/component/setUp/commissionDetails.vue')
  },
  {
    path: '/wagesDetails',
    name: 'wagesDetails',
    meta: {
      title: '收入明细'
    },
    component: () =>
      import('@/views/basic/home/component/component/setUp/wagesDetails.vue')
  },
  {
    path: '/contentDetails',
    name: 'contentDetails',
    meta: {
      title: '详情'
    },
    component: () =>
      import('@/views/basic/home/component/component/setUp/contentDetails.vue')
  },
  {
    path: '/cashWithdrawal',
    name: 'cashWithdrawal',
    meta: {
      title: '提现'
    },
    component: () =>
      import('@/views/basic/home/component/component/setUp/cashWithdrawal.vue')
  },
  {
    path: '/cashDetails',
    name: 'cashDetails',
    meta: {
      title: '提现详情'
    },
    component: () =>
      import('@/views/basic/home/component/component/setUp/cashDetails.vue')
  },
  {
    path: '/setup',
    name: 'setup',
    meta: {
      title: '设置'
    },
    component: () =>
      import('@/views/basic/home/component/component/setUp/setup.vue')
  },
  {
    path: '/changePassword',
    name: 'changePassword',
    meta: {
      title: '更换密码'
    },
    component: () =>
      import('@/views/basic/home/component/component/setUp/changePassword.vue')
  },
  {
    path: '/changePhoneNumber',
    name: 'changePhoneNumber',
    meta: {
      title: '更换手机号'
    },
    component: () =>
      import('@/views/basic/home/component/component/setUp/changePhoneNumber.vue')
  },
  {
    path: '/myMsg',
    name: 'myMsg',
    meta: {
      title: '个人资料'
    },
    component: () =>
      import('@/views/basic/home/component/component/setUp/myMsg.vue')
  },
  {
    path: '/realName',
    name: 'realName',
    meta: {
      title: '实名认证'
    },
    component: () =>
      import('@/views/basic/home/component/component/setUp/realName.vue')
  },
  {
    path: '/practisingQualification',
    name: 'practisingQualification',
    meta: {
      title: '执业资质'
    },
    component: () =>
      import('@/views/basic/home/component/component/setUp/practisingQualification.vue')
  },
  {
    path: '/changeBank',
    name: 'changeBank',
    meta: {
      title: '银行卡信息'
    },
    component: () =>
      import('@/views/basic/home/component/component/setUp/changeBank.vue')
  },
  {
    path: '/bankList',
    name: 'bankList',
    meta: {
      title: '银行卡列表'
    },
    component: () =>
      import('@/views/basic/home/component/component/setUp/bankList.vue')
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    meta: {
      title: '关于我们'
    },
    component: () =>
      import('@/views/basic/home/component/component/setUp/aboutUs.vue')
  },

  {
    path: '/artificialUnderwriting',
    name: 'artificialUnderwriting',
    meta: {
      title: '人工核保'
    },
    component: () =>
      import('@/views/basic/home/component/component/setUp/artificialUnderwriting.vue')
  },
  {
    path: '/drnews',
    name: 'drnews',
    meta: {
      title: '公司消息通知'
    },
    component: () => import('@/views/basic/drnews/index')
  },
  {
    path: '/morermhd',
    name: 'morermhd',
    meta: {
      title: '热门活动'
    },
    component: () => import('@/views/basic/rmhd/index')
  },
  {
    path: '/rmhddetail',
    name: 'rmhddetail',
    meta: {
      title: '热门活动'
    },
    component: () => import('@/views/basic/rmhd/component/detail')
  },
  {
    path: '/policycheck',
    name: 'policycheck',
    meta: {
      title: '保单检视'
    },
    component: () => import('@/views/basic/bdjs/index')
  },
  {
    path: '/messagepush',
    name: 'messagepush',
    meta: {
      title: '',
      keepAlive: true
    },
    component: () => import('@/views/basic/messagepush/index')
  },
  {
    path: '/diaoauth',
    name: 'diaoauth',
    meta: {
      title: '汇立天下：新时代保险普惠'
    },
    component: () => import('@/views/basic/oauth/income')
  },
  {
    path: '/mysc',
    name: 'mysc',
    meta: {
      title: '我的收藏'
    },
    component: () => import('@/views/basic/bdjs/index')
  },
  {
    path: '/ordercp',
    name: 'ordercp',
    meta: {
      title: '订单详情'
    },
    component: () => import('@/views/agent/ordercp/index')
  },
  {
    path: '/onFile',
    name: 'onFile',
    meta: {
      title: '产品备案'
    },
    component: () => import('@/views/agent/onFile/index')
  },
  {
    path: '/percenter',
    name: 'percenter',
    meta: {
      title: '学习详情'
    },
    component: () =>
      import('@/views/basic/home/component/component/xuexi/percenter.vue')
    // component: () => import('@/views/agent/ordercp/index')
  },
  {
    path: '/wzdetails',
    name: 'wzdetails',
    meta: {
      title: '文章详情'
    },
    component: () =>
      import('@/views/basic/home/component/component/xuexi/wzdetails.vue')
    // component: () => import('@/views/agent/ordercp/index')
  },
  {
    path: '/searchdata',
    name: 'searchdata',
    meta: {
      title: '搜索课程'
    },
    component: () =>
      import('@/views/basic/home/component/component/xuexi/searchdata.vue')
    // component: () => import('@/views/agent/ordercp/index')
  },
  {
    path: '/wrong',
    name: 'wrong',
    meta: {
      title: '错误页面'
    },
    component: () =>
      import('@/views/basic/set/wrong.vue')
    // component: () => import('@/views/agent/ordercp/index')
  },
]
