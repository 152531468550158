/*
 * @Author: 黄孝娟
 * @Date: 2019-11-04 15:26:22
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-05-26 11:27:46
 */

export default [
  {
    path: "/visitingcard",
    name: "visitingcard",
    meta: {
      title: "个人名片"
    },
    component: () =>
      import("@/views/abt/customerOperation/visitingcard/visitingcard/index")
  },
  {
    path: "/editcard",
    name: "editcard",
    meta: {
      title: "编辑名片"
    },
    component: () =>
      import("@/views/abt/customerOperation/visitingcard/editcard/index")
  },
  {
    path: "/changemsg",
    name: "changemsg",
    meta: {
      title: "交换信息列表"
    },
    component: () =>
      import("@/views/abt/customerOperation/visitingcard/changemsg/index")
  },
  {
    path: "/website",
    name: "website",
    meta: {
      title: "微官网"
    },
    component: () =>
      import("../../../views/abt/customerOperation/visitingcard/website/index")
  },
  {
    path: "/Hkbw",
    name: "Hkbw",
    meta: {
      title: "获客爆文",
      keepAlive: true
    },
    component: () => import("@/views/abt/customerOperation/hkbw/bwlist/index")
  },
  {
    path: "/Emplist",
    name: "Emplist",
    meta: {
      title: "我的文章"
    },
    component: () =>
      import("@/views/abt/customerOperation/hkbw/emplist/emplist")
  },
  {
    path: "/Libwarticle",
    name: "Libwarticle",
    meta: {
      title: ""
    },
    component: () => import("@/views/abt/customerOperation/hkbw/libw/article")
  },
  {
    path: "/Empshare",
    name: "Empshare",
    meta: {
      title: ""
    },
    component: () =>
      import("@/views/abt/customerOperation/hkbw/emplibw/empshare")
  },
  {
    path: "/Emparticle",
    name: "Emparticle",
    meta: {
      title: ""
    },
    component: () =>
      import("@/views/abt/customerOperation/hkbw/emplibw/emparticle")
  },
  {
    path: "/makebw",
    name: "makebw",
    meta: {
      title: "制作文章"
    },
    component: () => import("@/views/abt/customerOperation/hkbw/makebw/editor")
  },
  {
    path: "/editarticle",
    name: "editarticle",
    meta: {
      title: "制作文章"
    },
    component: () =>
      import("@/views/abt/customerOperation/hkbw/makebw/editarticle")
  },
  {
    path: "/acquisitionposter",
    name: "acquisitionposter",
    meta: {
      title: "展业海报"
    },
    component: () =>
      import("@/views/abt/customerOperation/acquisitionposter/acquisitionposter")
  },
  {
    path: "/myposter",
    name: "myposter",
    meta: {
      title: "我的海报"
    },
    component: () =>
      import("@/views/abt/customerOperation/acquisitionposter/myposter")
  },
  {
    path: "/weatherposter",
    name: "weatherposter",
    meta: {
      title: "天气海报"
    },
    component: () =>
      import("@/views/abt/customerOperation/weatherposter/weatherposter")
  },
  {
    path: "/greetingcard",
    name: "greetingcard",
    meta: {
      title: "温馨贺卡"
    },
    component: () =>
      import("@/views/abt/customerOperation/greetingcard/greetingcard")
  },
  {
    path: "/greetingcard/carddetail",
    name: "carddetail",
    meta: {
      title: "创建贺卡"
    },
    component: () =>
      import("@/views/abt/customerOperation/greetingcard/carddetail")
  },
  {
    path: "/greetingcard/cardshare",
    name: "cardshare",
    meta: {
      title: "分享贺卡"
    },
    component: () =>
      import("@/views/abt/customerOperation/greetingcard/cardshare")
  },
  {
    path: "/mycard",
    name: "mycard",
    meta: {
      title: "我的贺卡"
    },
    component: () => import("@/views/abt/customerOperation/greetingcard/mycard")
  },
  {
    path: "/invitationletter",
    name: "invitationletter",
    meta: {
      title: "邀请函"
    },
    component: () =>
      import("@/views/abt/customerOperation/invitationletter/invitationletter")
  },
  {
    path: "/invitationletter/activitylist",
    name: "activitylist",
    meta: {
      title: "我的邀请函"
    },
    component: () =>
      import("@/views/abt/customerOperation/invitationletter/activitylist")
  },
  {
    path: "/invitationletter/activity",
    name: "activity",
    meta: {
      title: "邀请函活动"
    },
    component: () =>
      import("@/views/abt/customerOperation/invitationletter/activity")
  },
  {
    path: "/invitationletter/createactivity",
    name: "createactivity",
    meta: {
      title: "创建邀请函活动"
    },
    component: () =>
      import("@/views/abt/customerOperation/invitationletter/createactivity")
  },
  {
    path: "/invitationletter/invite",
    name: "invite",
    meta: {
      title: "报名邀约"
    },
    component: () =>
      import("@/views/abt/customerOperation/invitationletter/invite")
  },
  {
    path: "/invitationletter/applymsg",
    name: "applymsg",
    meta: {
      title: "报名信息"
    },
    component: () =>
      import("@/views/abt/customerOperation/invitationletter/applymsg")
  },
  {
    path: "/message",
    name: "message",
    meta: {
      title: "消息提醒",
      keepAlive: true
    },
    component: () => import("@/views/abt/customerOperation/message/index")
  },
  {
    path: "/looked",
    name: "looked",
    meta: {
      title: "消息中心"
    },
    component: () => import("@/views/abt/customerOperation/looked/looked")
  },
  {
    path: "/wholookme",
    name: "wholookme",
    meta: {
      title: "谁在看我"
    },
    component: () => import("@/views/abt/customerOperation/wholookme/wholookme")
  },
  {
    path: "/clientsort",
    name: "clientsort",
    meta: {
      title: "客户挖掘"
    },
    component: () => import("@/views/abt/customerOperation/clientsort/index")
  },
  {
    path: "/clientAnalysis",
    name: "clientAnalysis",
    meta: {
      title: "客户挖掘"
    },
    component: () =>
      import("@/views/abt/customerOperation/clientsort/clientanalysis/index")
  },
  {
    path: "/zxlist",
    name: "zxlist",
    meta: {
      title: "咨询列表"
    },
    component: () => import("@/views/abt/customerOperation/looked/zxlist")
  },
  {
    path: "/Consult",
    name: "Consult",
    meta: {
      title: "消息咨询"
    },
    component: () => import("@/views/abt/customerOperation/consult/index")
  },
  {
    path: "/materiallibrary",
    name: "materiallibrary",
    meta: {
      title: "素材库"
    },
    component: () =>
      import("@/views/abt/customerOperation/materiallibrary/materiallibrary")
  },
  {
    path: "/abtreport",
    name: "abtreport",
    meta: {
      title: "投诉"
    },
    component: () =>
      import("@/views/abt/customerOperation/common/report/report")
  },
  {
    path: "/complain",
    name: "complain",
    meta: {
      title: "投诉"
    },
    component: () =>
      import("@/views/abt/customerOperation/common/complain/complain")
  },
  {
    path: "/morning",
    name: "morning",
    meta: {
      title: "东瑞早报"
    },
    component: () => import("@/views/abt/customerOperation/morning/index")
  }
];
