/*
 * @Descripttion:
 * @version:
 * @Author: 贾峰
 * @Date: 2019-12-31 11:39:47
 * @LastEditors  : 贾峰
 * @LastEditTime : 2019-12-31 11:43:44
 */
export default [
  {
    path: '/businesstrack',
    name: 'businesstrack',
    meta: {
      title: '业务追踪'
    },
    component: () => import('@/views/hx/businesstrack')
  }
]
