/*
 * @Author:weixin
 * @Date: 2019-12-30 14:34:31
 * @LastEditors: weixin
 * @LastEditTime: 2019-12-30 19:34:33
 */
export default [
  {
    path: '/sett',
    name: 'sett',
    meta: {
      title: '理赔保单'
    },
    component: () =>
      import('@/views/hx/sett/index')
  },
  {
    path: '/lpShow',
    name: 'lpShow',
    meta: {
      title: '保单详情'
    },
    component: () =>
      import('@/views/hx/sett/component/index')
  }
]
