/*
 * @Author: 王鹏
 * @Date: 2019-09-10 16:36:46
 * @LastEditors: 刘格优
 * @LastEditTime: 2021-02-07 15:27:18
 */

export default {
  /**
   * @description 配置显示在浏览器标签的title
   */
  title: '渠道管理平台',

  /**
   * @description api请求基础路径
   */
  baseUrl: {
    // dev: 'http://localhost:8086',
    dev:'http://qd.devnhcs.hcpaiahk.com/',
    // dev: 'http://192.168.5.30:8082',

    /* 测试 */
    test: `http://${window.location.hostname.split('.')[0]}.devnhcs.hcpaiahk.com`,
    /* uat */
    // pro: `http://${window.location.hostname.split('.')[0]}.uatcs.hcpaia.com`
    // 南华正式
    // pro: `http://${window.location.hostname.split(".")[0]}.cs.hcpaia.com`
    pro: `https://${window.location.hostname.split(".")[0]}.cs.hckjcx.com`
  },
  // appId: 'wxb78763020baee641',
  // 寿险管理端ossurl
  // pcossurl: "https://hxyw-test.oss-cn-beijing.aliyuncs.com/",
  // 正式
  pcossurl: 'https://hxywprod.oss-cn-shenzhen.aliyuncs.com/',
  // 正式
  cvu: 'https://12bao.oss.zzschj.com/',
  /* 动态域名 */
  redirect_uri: `https://${window.location.hostname}`,
  /**
   * @description 默认打开的首页的路由name值，默认为home
   */
  homeName: 'home',
  /**
   *  @description 打包不同的子模块, 默认为 'all' 全部打包
   *               参数支持字符串和数组,如果需要全部打包请将参数直接填写为 'all'
   *               如果要分模块打包请将打包的子模块文件名放入数组当中既可 ['xxx','xxx']
   */
  modules: 'all'
}
