/*
 * @Author:张天鹏
 * @Date: 2019-12-10 19:34:31
 * @LastEditors: 张天鹏
 * @LastEditTime: 2020-12-16 21:57:38
 */
export default [
  {
    path: '/productlist',
    name: 'productlist',
    meta: {
      title: '计划书'
    },
    component: () =>
      import('@/views/jhs/productlist/index')
  },
  {
    path: '/search',
    name: 'search',
    meta: {
      title: '搜索'
    },
    component: () =>
      import('@/views/jhs/productlist/component/search')
  },
  {
    path: '/history_jhs',
    name: 'history_jhs',
    meta: {
      title: '历史计划书'
    },
    component: () =>
      import('@/views/jhs/productlist/component/history_jhs')
  },
  {
    path: '/makeout',
    name: 'makeout',
    meta: {
      title: '填写信息'
    },
    component: () =>
      import('@/views/jhs/productlist/component/makeout')
  },
  {
    path: '/responsibility',
    name: 'responsibility',
    meta: {
      title: '产品信息'
    },
    component: () =>
      import('@/views/jhs/productlist/component/responsibility')
  },
  {
    path: '/pdf_template',
    name: 'pdf_template',
    meta: {
      title: 'PDF版计划书'
    },
    component: () =>
      import('@/views/jhs/productlist/component/pdf_template')
  },
  {
    path: '/revealpdf',
    name: 'revealpdf',
    meta: {
      title: 'PDF版计划书'
    },
    component: () =>
      import('@/views/jhs/productlist/component/revealpdf')
  },
  {
    path: '/jhsinformation',
    name: 'jhsinformation',
    meta: {
      title: '签单信息'
    },
    component: () =>
      import('@/views/jhs/productlist/component/jhsinformation')
  },
  {
    path: '/xqinformation',
    name: 'xqinformation',
    meta: {
      title: '签单信息'
    },
    component: () =>
      import('@/views/jhs/productlist/component/xqinformation')
  }
]
