/*
 * @Descripttion:
 * @version:
 * @Author: 王鹏
 * @Date: 2019-11-04 18:07:26
 * @LastEditors: 王广婷
 * @LastEditTime: 2020-04-09 20:44:43
 */
import agent from './agent'
import abt from './abt'
import abd from './abd'
import nbs from './nbs'
import jhs from './jhs'
import cpk from './cpk'
import basic from './basic'
import hx from './hx'
import smarkboard from './smarkboard'
import xsqd from './xsqd'
import xy from './common'
import openpage from './openpage'
export default {
  agent,
  abt,
  abd,
  nbs,
  jhs,
  cpk,
  basic,
  hx,
  smarkboard,
  xsqd,
  xy,
  openpage
}
