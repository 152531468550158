/*
 * @Author:魏鑫
 * @Date: 2020-01-09 11:50:31
 * @LastEditors  : 李波
 * @LastEditTime : 2020-01-14 15:01:55
 */
export default [
  {
    path: '/effectivereport',
    name: 'effectivereport',
    meta: {
      title: '生效报表'
    },
    component: () =>
      import('@/views/smarkboard/effectivereport/index')
  }
]
