/*
 * @Author:王广婷
 * @Date: 2019-11-04 16:56:31
 * @LastEditors  : 贾峰
 * @LastEditTime : 2020-01-15 19:27:58
 */
export default [
  {
    path: '/bdtg',
    name: 'bdtg',
    meta: {
      title: '托管客户'
    },
    component: () => import('@/views/abd/bdtg/index')
  },
  {
    path: '/report',
    name: 'report',
    meta: {
      title: '家庭报告'
    },
    component: () => import('@/views/abd/bdtg/component/report')
  },
  {
    path: '/customercenter',
    name: 'customercenter',
    meta: {
      title: '保单整理'
    },
    component: () => import('@/views/abd/bdtg/component/customercenter')
  },
  {
    path: '/addpolicy',
    name: 'addpolicy',
    meta: {
      title: '上传保单'
    },
    component: () => import('@/views/abd/bdtg/component/addpolicy')
  },
  {
    path: '/sharereport',
    name: 'sharereport',
    meta: {
      title: '分享报告'
    },
    component: () => import('@/views/abd/bdtg/component/sharereport')
  },
  {
    path: '/recommendpage',
    name: 'recommendpage',
    meta: {
      title: '保单整理'
    },
    component: () => import('@/views/abd/bdtg/component/recommendpage')
  }
]
