/*
 * @Author: 刘格优
 * @Date: 2019-11-04 11:36:58
 * @LastEditors: 李波
 * @LastEditTime: 2020-06-23 18:06:17
 */
import { Toast } from 'mand-mobile'
import { setStorage } from '@/lib/util'
import { checkSign, temporary, assemblydata } from '@/api/basic/index'
export default {
  state: {
    isloading: false
  },
  getters: {
    getIsloading: state => state.isloading
  },
  mutations: {
    setIsloading (state, isloading) {
      state.isloading = isloading
    }
  },
  actions: {
    SALES_PERMISSION (context, params) {
      setStorage('scrollTop', document.scrollingElement.scrollTop)
      return new Promise(resolve => {
        let httpurl = params.url.split('param=')[0]
        let param = params.url.split('param=')[1]
        // if(!param) {
        //   resolve()
        //   return
        // }
        param =  param&&param.includes('&') ? param.split('&')[0] : param
        checkSign({ param }).then(res => {
          if (res.data.code == '200') {
            let response = res.data.data
            response.urltype = httpurl.includes('nhwx') ? 'N' : 'W'
            // 保险公司为易升云服调用接口存临时业务员信息
            if (params.baseid == 'YSYF') {
              temporary(res.data.data).then(success => {
                response.userTrack = success.data.data
                resolve(response)
              })
            } else if (params.baseid == 'ZXJJ') {  //中兴技术产品接口调用
              temporary(res.data.data).then(success => {
                response.agent = success.data.data
                resolve(response)
              })
            }else if (params.baseid == 'ZAZX') {// 如果为众安在线的h5产品，进行加密
              assemblydata({
                operno: response.empno,
                salecomid: response.comid,
                prodcode: response.prodcode
              }).then(res => {
                response.bizContent = res.data.data
                resolve(response)
              })
            } else {
              resolve(response)
            }
          } else if (res.data.code == '100') {
            Toast.info(res.data.msg)
          }
        })
      })
    }
  }
}
