/*
 * @Author:王广婷
 * @Date: 2019-11-04 16:56:31
 * @LastEditors: 贾峰
 * @LastEditTime: 2019-12-06 09:14:08
 */
export default [
  {
    path: '/customer',
    name: 'customer',
    meta: {
      title: '托管客户'
    },
    component: () => import('@/views/abd/customer/index')
  },
  {
    path: '/detail',
    name: 'detail',
    meta: {
      title: '个人报告'
    },
    component: () => import('@/views/abd/customer/component/detail')
  },
  {
    path: '/upload',
    name: 'upload',
    meta: {
      title: '解析追踪'
    },
    component: () => import('@/views/abd/customer/component/upload')
  }
]
