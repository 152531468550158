/*
 * @Author:王广婷
 * @Date: 2019-11-04 16:56:31
 * @LastEditors: 顾俊杰
 * @LastEditTime: 2020-12-28 14:55:04
 */
export default [{
  path: '/teamzy',
  name: 'teamzy',
  meta: {
    title: '增员'
  },
  component: () => import('@/views/agent/teamzy/index')
},
{
  path: '/addmember',
  name: 'addmember',
  meta: {
    title: '入司申请'
  },
  component: () => import('@/views/agent/teamzy/addmember')
},
{
  path: '/guarantor',
  name: 'guarantor',
  meta: {
    title: '入司申请'
  },
  component: () => import('@/views/agent/teamzy/guarantor')
},
{
  path: '/family1',
  name: 'family1',
  meta: {
    title: '入司申请'
  },
  component: () => import('@/views/agent/teamzy/family')
},
{
  path: '/edu',
  name: 'edu',
  meta: {
    title: '入司申请'
  },
  component: () => import('@/views/agent/teamzy/edu')
},
{
  path: '/work',
  name: 'work',
  meta: {
    title: '入司申请'
  },
  component: () => import('@/views/agent/teamzy/work')
},
{
  path: '/successpage',
  name: 'successpage',
  meta: {
    title: '入司申请'
  },
  component: () => import('@/views/agent/teamzy/successpage')
},
{
  path: '/myTeam',
  name: 'myTeam',
  meta: {
    title: '我的团队'
  },
  component: () => import('@/views/agent/myTeam/index')
},
{
  path: '/wxmyteam',
  name: 'wxmyteam',
  meta: {
    title: '我的团队'
  },
  component: () => import('@/views/agent/wxmyteam/index')
},
{
  path: '/teammembers',
  name: 'teammembers',
  meta: {
    title: '查看团队成员'
  },
  component: () => import('@/views/agent/wxmyteam/teammembers')
},
{
  path: '/personaldetails',
  name: 'personaldetails',
  meta: {
    title: '个人明细'
  },
  component: () => import('@/views/agent/wxmyteam/personaldetails')
},
{
  path: '/radarCharge',
  name: 'radarCharge',
  meta: {
    title: '主管雷达'
  },
  component: () => import('@/views/agent/radarCharge/index')
},
{
  path: '/teamActivity',
  name: 'teamActivity',
  meta: {
    title: '团队活动量'
  },
  component: () => import('@/views/agent/teamActivity/index')
},
{
  path: '/productDisplay',
  name: 'productDisplay',
  meta: {
    title: '产品展示'
  },
  component: () => import('@/views/agent/productDisplay/index')
},
{
  path: '/productShow',
  name: 'productShow',
  meta: {
    title: '产品详情'
  },
  component: () => import('@/views/agent/productDisplay/productShow/index')
},
{
  path: '/productDisplayNhsx',
  name: 'productDisplayNhsx',
  meta: {
    title: '产品展示'
    // keepAlive: true,
  },
  component: () => import('@/views/agent/productDisplayNhsx/index')
},
{
  path: '/tookeen',
  name: 'tookeen',
  meta: {
    title: '微信拓客'
    // keepAlive: true,
  },
  component: () => import('@/views/agent/tookeen/index')
},
{
  path: '/productShowNhwx',
  name: 'productShowNhwx',
  meta: {
    title: '产品展示'
  },
  component: () =>
      import('@/views/agent/productDisplayNhsx/productShow/index')
},
{
  path: '/information',
  name: 'information',
  meta: {
    title: '产品资料'
  },
  component: () => import('@/views/agent/information/index')
},
{
  path: '/pdfinformation',
  name: 'pdfinformation',
  meta: {
    title: '产品资料'
  },
  component: () => import('@/views/agent/information/pdfinformation')
},
{
  path: '/Invitenew',
  name: 'Invitenew',
  meta: {
    title: '邀新',
    keepAlive: true
  },
  component: () => import('@/views/agent/yaoxin/index')
},
{
  path: '/toInvitenew',
  name: 'toInvitenew',
  meta: {
    title: '邀新'
  },
  component: () => import('@/views/agent/toyaoxin/index')
},
{
  path: '/Invitedetail',
  name: 'Invitedetail',
  meta: {
    title: '邀新详情'
  },
  component: () => import('@/views/agent/toyaoxin/yxdetail')
},
{
  path: '/rshdgz',
  name: 'rshdgz',
  meta: {
    title: '活动规则'
  },
  component: () => import('@/views/agent/toyaoxin/hdgz')
},
{
  path: '/management',
  name: 'management',
  meta: {
    title: '产品编辑'
  },
  component: () => import('@/views/agent/productmanagement/management/index')
},
{
  path: '/microshop',
  name: 'microshop',
  meta: {
    title: '个人微店'
  },
  component: () => import('@/views/agent/productmanagement/microshop/index')
},
{
  path: '/nanhuaarrondi',
  name: 'nanhuaarrondi',
  meta: {
    title: '南华专区'
  },
  component: () => import('@/views/agent/nanhuaarrondi/index')
},
{
  path: '/professional',
  name: 'professional',
  meta: {
    title: '执业资质认证',
    keepAlive: true
  },
  component: () => import('@/views/agent/teamzy/Professional')
},
{
  path: '/dzincome',
  name: 'dzincome',
  meta: {
    title: '电子入司',
    keepAlive: true
  },
  component: () => import('@/views/agent/teamzy/dzincome')
},
{
  path: '/fundamentals',
  name: 'fundamentals',
  meta: {
    title: '保险基础知识'
  },
  component: () => import('@/views/agent/teamzy/fundamentals')
},
{
  path: '/signature',
  name: 'signature',
  meta: {
    title: '签名'
  },
  component: () => import('@/views/agent/teamzy/signature')
},
{
  path: '/dzincomeoauth',
  name: 'dzincomeoauth',
  meta: {
    title: '电子入司'
  },
  component: () => import('@/views/agent/teamzy/dzincomeoauth')
},
{
  path: '/addimage',
  name: 'addimage',
  meta: {
    title: '补充影像'
  },
  component: () => import('@/views/agent/teamzy/addimage')
},
{
  path: '/rzxzdetails',
  name: 'rzxzdetails',
  meta: {
    title: '入司须知'
  },
  component: () => import('@/views/agent/teamzy/component/rzxzdetails')
},
{
  path: '/jldetail',
  name: 'jldetail',
  meta: {
    title: '数据明细'
  },
  component: () => import('@/views/agent/toyaoxin/jldetail')
},
{
  path: '/orderdetail',
  name: 'orderdetail',
  meta: {
    title: '保单明细'
  },
  component: () => import('@/views/agent/myTeam/order')
},
{
  path: '/promptpage',
  name: 'promptpage',
  meta: {
    title: '提示'
  },
  component: () => import('@/views/agent/promptPage/index')
}
]
