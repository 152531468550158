/*
 * @Descripttion:
 * @version:
 * @Author: 赵琪
 * @Date: 2019-1-6 11:39:47
 * @LastEditors  : 赵琪
 * @LastEditTime : 2019-1-6 11:43:44
 */
export default [
    {
      path: '/simplex',
      name: 'simplex',
      meta: {
        title: '出单工号'
      },
      component: () => import('@/views/hx/simplex/index')
    }
  ]